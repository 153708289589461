<template>
  <SubPageWrapper empty-cols>
    <template #breadcrumbs>
      <SlBreadcrumb @click="goToProjectsList">
        {{ $t('Main.Ui.btStartPageProjects') }}
      </SlBreadcrumb>
      <SlBreadcrumb
        :index="1"
        active
      >
        {{ $t('Web.Backups.Title', { 1: originProjectName }) }}
      </SlBreadcrumb>
    </template>

    <ContentBlock>
      <div class="subpage__tabs">
        <SlTabList
          v-model="tabModel"
          :tabs="tabsConfig"
          horizontal
        />
      </div>
      <div class="subpage__table-wrapper">
        <SlTabContent
          v-for="tab in tabsConfig"
          :key="tab.value"
          :value="tab.value"
          :tab-value="tabModel"
        >
          <div class="subpage__table">
            <SlTable
              :id="`backup-list-${tab.value}`"
              :headers="headers"
              header-unique-key="key"
              header-name-key="name"
              :rows="rows"
              unique-key="id"
              :max-height="1100"
              :initial-col-sizes="initialColSizes"
              :hidden-columns-keys="['id', 'ActionFgs']"
              :col-initial-width="40"
              :row-height="40"
              :col-resize="false"
              :value-parser="parseValue"
              list
              highlight-row
            >
              <template #header-ActionFgs-last />

              <template #ActionFgs-last="slotScope">
                <BackupActionsDropdown
                  v-if="slotScope.cellValue"
                  :backup="slotScope.row"
                  :actions="slotScope.cellValue.val"
                  :origin-name="originProjectName"
                />
              </template>

              <template #loader>
                <SlOverlay :show="isTableLoading" />
              </template>

              <template #no-data>
                <SlNoData>
                  <template #image>
                    <icon
                      data="@icons/illustration/no-table-rows.svg"
                      class="illustration-md"
                    />
                  </template>
                  <template #title>
                    {{ noData.title }}
                  </template>
                  <template #text>
                    {{ noData.text }}
                  </template>
                </SlNoData>
              </template>
            </SlTable>
          </div>
        </SlTabContent>
      </div>
    </ContentBlock>
  </SubPageWrapper>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import BackupActionsDropdown from '@/components/Backups/BackupActionsDropdown.vue';
import modalIds from '@/config/shared/modalsId.config';
import { routeNames } from '@/config/router/router.config';
import { backupTabsConfig, backupTypes } from '@/config/project/backups.config';
import { sendRequests } from '@/helpers/lastChange/sendRequests.js';

export default {
  name: 'Backups',
  components: {
    BackupActionsDropdown
  },
  data() {
    return {
      modalIds,
      tabModel: backupTypes.DAILY,
      isTableLoading: false,
      isProjectOpensisProjectOpens: false,
      tabsConfig: backupTabsConfig(this)
    };
  },
  computed: {
    ...mapState({
      backups: (state) => state.manageProjects.backups
    }),
    ...mapGetters({
      getProjectById: 'manageProjects/getProjectById'
    }),
    originProjectName() {
      return this.getProjectById(this.$route.params.originId)?.name ?? '';
    },
    table() {
      return this.backups[this.tabModel];
    },
    headers() {
      return this.table?.headers ?? [];
    },
    rows() {
      return this.table?.rows ?? [];
    },
    initialColSizes() {
      if (this.isEventsTab) {
        return ['calc(70% - 40px)', '30%', '40px'];
      }

      return ['calc(100% - 40px)', '40px'];
    },
    isEventsTab() {
      return this.tabModel === backupTypes.EVENTS;
    },
    noData() {
      return {
        title: this.$t('Web.Backups.NoBackupsTitle'),
        text: this.isEventsTab
          ? this.$t('Web.Backups.NoEventsText')
          : this.$t('Web.Backups.NoPeriodicalText')
      };
    }
  },
  watch: {
    tabModel(value) {
      if (!this.backups[value]) {
        this.loadBackups(value);
      }
    }
  },
  async beforeMount() {
    try {
      this.isTableLoading = true;
      await sendRequests(this.$sl_routeName, this.tabModel);
    } finally {
      this.isTableLoading = false;
    }
  },
  destroyed() {
    this.resetState();
  },
  methods: {
    ...mapActions({
      fetchBackups: 'manageProjects/backups/fetchBackups',
      resetState: 'manageProjects/backups/resetState'
    }),
    async loadBackups() {
      try {
        this.isTableLoading = true;

        await this.fetchBackups(this.tabModel);
      } finally {
        this.isTableLoading = false;
      }
    },
    parseValue(cellValue) {
      if (!cellValue) {
        return '';
      }

      if (typeof cellValue !== 'object') {
        return cellValue;
      }

      return cellValue.val ?? '';
    },
    goToProjectsList() {
      this.$router.push({
        name: routeNames.PROJECT_LIST
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.content-block {
  .subpage__table {
    height: auto;
  }

  ::v-deep .sl-table {
    max-width: 100%;
  }
}
</style>
