<template>
  <SlDropdown
    v-bind="$attrs"
    placement="bottom-end"
  >
    <template #target>
      <SlButton
        variant="tertiary"
        color="grey"
        size="xs"
        icon
      >
        <icon
          data="@icons/vertical-dots.svg"
          class="size-20 color-grey"
        />
      </SlButton>
    </template>
    <template #content>
      <SlDropdownOption
        v-for="action in allowedActions"
        :key="action.flag"
        select-close
        @select="action.action(backup)"
      >
        <template #prepend>
          <icon
            v-if="action.icon"
            class="fill-off size-16 stroke-icon--grey"
            :data="require(`@icons/${action.icon}.svg`)"
          />
        </template>
        {{ action.label }}
      </SlDropdownOption>
    </template>
  </SlDropdown>
</template>

<script>
import { mapActions } from 'vuex';
import { fileSaver } from '@/mixins/webAPI';
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';

export default {
  name: 'BackupActionsDropdown',
  mixins: [fileSaver, modal],
  props: {
    actions: {
      type: Number,
      required: true
    },
    backup: {
      type: Object,
      required: true
    },
    originName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      actionsConfig: [
        {
          flag: 0,
          label: this.$t('Web.Backups.RunAsTemp'),
          icon: 'start-project',
          action: this.runAsTemp
        },
        {
          flag: 1,
          label: this.$t('Web.Backups.Restore'),
          icon: 'restore',
          action: this.handleRestoreBackup
        },
        {
          flag: 2,
          label: this.$t('Web.Project.Download'),
          icon: 'download',
          action: this.handleDownloadBackup
        }
      ]
    };
  },
  computed: {
    allowedActions() {
      return this.actionsConfig.filter(action => this.actions & 1 << action.flag);
    }
  },
  methods: {
    ...mapActions({
      runAsTemp: 'manageProjects/backups/runAsTemp',
      restoreBackup: 'manageProjects/backups/restoreBackup',
      downloadBackup: 'manageProjects/backups/downloadBackup'
    }),
    getBackupName({ Date, Version } = {}) {
      return `${Version?.val || ''} ${Date?.val || ''}`.trim();
    },
    handleRestoreBackup(backup) {
      this.showModal(modalsId.SL_CONFIRM_MODAL, {
        title: this.$t('Web.Backups.RestoreTitle', {
          1: this.getBackupName(backup)
        }),
        text: this.$t('Web.Backups.RestoreText'),
        icon: 'style_warning_double',
        confirmText: this.$t('Web.Backups.BtnRestore'),
        confirmCallback: () => this.restoreBackup(backup)
      });
    },
    handleDownloadBackup({ id } = {}) {
      this.saveFile(this.downloadBackup.bind(this, { id }));
    }
  }
};
</script>